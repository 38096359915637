.todo-add{
display: flex;
justify-content: center;
align-items:center ;
 
}
.todo{
   width: 600px;
   border-radius: 20px;
   background-color: #fff;
   margin: auto;
   display: flex;
   flex-direction: column;
   padding:0px 44px;
   padding-bottom: 30px;
   margin-bottom: 100px;

}
.todo-input{
   border-radius: 25px;
   background: #edeef0;
   border: none;
   outline: none;
   width: 206px;
   height: 30px;
   padding-left: 35px;
   font-size: 14px;
}
.todo_add_btn{
   border-radius: 50px;
   background: #ff6739;
   width: 87px;
   height:30px ;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #fff;
   font-size: 14px;
   font-weight: 600px;
   cursor: pointer;
}
.fields_align{
   padding-top: 5%;
}
.data-table-align {
   display: flex;
}
.template_textfeild {
   margin-top: 2%;
   // margin-bottom: 2%;
}
.template_tolerance_textfield {
   margin-top: 2%;
   width: auto;
}
button.template_btn_align {
   margin-top: 2%;
}
.data-table-align {
   display: flex;
  }

.css-zow5z4-MuiGrid-root {
   box-sizing: border-box;
   display: flex;
   flex-wrap: wrap;
   width: 100%;
   flex-direction: row;
   width: calc(100% + 24px);
   margin-left: -24px;
   flex-basis: 100%;
   flex-grow: 0;
}
.create_temp_align{
   width: 33.33%;
}
.css-xxkjhs-MuiButtonBase-root-MuiButton-root.Mui-disabled {
   background-color: #2251a9;
   color: #fff;
}

.align-grid-elements {
   margin-top: 1%;
}
.smart-style-2{
.document-option:hover,
.document-option.Mui-selected,
.document-option.Mui-selected:hover {
  background-color: grey;
}

.document-option.selected {
  background-color: lightgrey !important;
}

.document-option:hover {
  background-color: lightgrey !important;
}
.logDataCount {
   background-color: lightgrey;
   border-radius: 10px;
   padding: 0px 10px;
   display: inline-block;
   margin-left: 2%;
 }
}
.smart-style-1{
   .document-option:hover,
   .document-option.Mui-selected,
   .document-option.Mui-selected:hover {
     background-color: grey;
     color:#fff;
   }
   
   .document-option.selected {
     background-color: grey !important;
     color:#fff;
   }
   
   .document-option:hover {
     background-color: grey !important;
     color:rgba(255, 255, 255, 0.87);
   }
   .logDataCount {
      background-color: rgba(255, 255, 255, 0.87);
      color:#333;
      border-radius: 10px;
      padding: 0px 10px;
      display: inline-block;
      margin-left: 2%;
    }
   }
   .download-button {
      background-color: #4CAF50; /* Green */
      border: none;
      color: rgba(255, 255, 255, 0.87);
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      border-radius: 4px;
    }
    
    .download-button:hover {
      background-color: #45a049; /* Darker green */
      color:#c4c4c4 !important;
    }
    .smart-style-2{
    .div-section {
      border: 1px solid rgb(212, 212, 212); 
      background-color: rgba(255, 255, 255, 0.87);
      color: #000; /* Text color */
      padding: 10px; /* Add some padding */
      border-radius: 5px; /* Add rounded corners */
      width: 100%;
      height: 3%;
      margin-top: 20px;
    }
   }
   .smart-style-1{
      .div-section {
        border: 1px solid rgb(29, 29, 29); /* Border with color */
        background-color: rgb(29, 29, 29); /* Background color */
        color: rgba(255, 255, 255, 0.87); /* Text color */
        padding: 10px; /* Add some padding */
        border-radius: 5px; /* Add rounded corners */
        width: 100%;
        height: 3%;
        margin-top: 20px;
        
      }
      td.overview-head-sub-head {
        color: rgba(255, 255, 255, 0.87) !important;
        background-color: #212020  !important;
      }
      td.overview-item-section {
        background-color: #121212;
      }
      .overview-item-row-sec {
      background-color: #141414 !important;
      }
      table.overview-table {
      border: 0px solid rgba(255, 255, 255, 0.87) !important;
      }
      .overview-item-row-sec {
      background-color: #212020   !important;
      }
      .overview-item-section{
       background-color: #212020  !important;
    }
     }
     //----------------data--------------------------
     table.overview-table {
      border: 1px solid rgba(255, 255, 255, 0.87) !important;
  }
  p.over-viewtable-content{
  line-height: 1.5rem;
  font-family: Poppins, sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  }
.overview-accordian-sec {
    padding: 0px !important; 
}
.overview-item-row-sec {
  background-color: rgba(255, 255, 255, 0.87) !important;
}
td.overview-item-section {
  font-weight: 100 !important;
}
.header-total-view.MuiBox-root.css-0 {
  display: flex !important;
}
h5.header-total-view-sec1{
  width: 96% !important;
}
span.ovrvew-item-id-sep {
  font-weight: 100;
  padding-left: 40px;
}
span.ovrvew-item-desc-sep {
  font-weight: 100;
  padding-left: 16px;
}
.bar-section{
  width: 87%;
}
td.bar-sec-cndtn-btn {
  border-radius: 7px;
  height: 30px;
  width: auto;
  line-height: 0.5 !important;
  margin-top: 1%;
}
.rslt_cmn_hed_sec.MuiBox-root.css-0 {
  display: flex;
  align-items: center;
}
.result_no_header{
  width: 60%;
  height: 40px;
  border: 1px solid #e41616 !important;
  background-color: #e41616 !important;
  color: #c7c0c0;
  padding-top: 1%;
}

.sec_data_set {
  width: 22% !important;
}
.sec-align {
  width: 22%;
} 
.smart-style-1{
  .info_styl_text {
    background-color: #ff000000 !important;
    color: #fff !important;
}
}

@media (max-width: 1100px) {
.fltr_box_align.MuiBox-root.css-k008qs {
  display: block;
}
.dcumnt-optn-styl {
  margin-top: 3%;
}
.rslt_sec_box {
  display: block;
  justify-content: space-between;
  margin-top: 40px !important;
}
.rslt_data_styl {
  width: 100% !important;
}
}
@media (max-width:1600px) and (min-width: 1100px)  {
.rslt_data_styl {
  width: 60% !important;
}

}

